import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {Gallery}    from "src/sections/News/NewsLayout/Gallery";
import {List}       from "src/sections/News/NewsLayout/List";
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_15}    from "types/News"

const Article = () => {
  const data = useNewsData(NEWS_15);

  return (
    <NewsLayout data={data}>
      <TextBlock data={data.texts.first}/>
      <List data={data.lists.first}/>
      <TextBlock data={data.texts.second}/>
      <Gallery data={data.gallery}/>
    </NewsLayout>
  )
}

export default Article;
